import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    paddingTop: '6rem', // Adjust this value to ensure content is not under the navigation bar
    paddingBottom: '2rem',
    minHeight: '100vh',
    backgroundColor: '#ffffff', // White background
    color: '#000000', // Black text color
});

const CompanyPolicy = () => {
    return (
        <StyledContainer maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Mars Reel Media Corp Company Policy
            </Typography>
            <Typography variant="h6" gutterBottom>
                Introduction
            </Typography>
            <Typography paragraph>
                Mars Reel Media Corp, through its MarsReel Academy, is dedicated to providing premier sports camps for boys and girls aged 13 to 18 years. Our mission is to foster athletic development and personal growth in a safe and supportive environment. This document outlines the policies governing our operations, ensuring that all participants, parents, and staff understand their roles, responsibilities, and the standards to which we adhere.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Registration and Enrollment
            </Typography>
            <Typography paragraph>
                1. <strong>Eligibility</strong>: MarsReel Academy camps are open to boys and girls aged 13 to 18 years.<br />
                2. <strong>Registration</strong>: Participants must complete an online registration form. Parents or guardians must provide accurate information and ensure all required fields are completed.<br />
                3. <strong>Fees and Payment</strong>: All fees must be paid in full prior to the start of the camp. We accept various payment methods, and any payment issues must be resolved before participation.<br />
                4. <strong>Cancellation and Refunds</strong>: Refunds are evaluated on a case-by-case basis. While we do offer refunds for certain circumstances, such as accidental charges, they are generally issued as credits to the participant's account. Refund requests are processed within 7 days.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Camp Operations
            </Typography>
            <Typography paragraph>
                1. <strong>Schedule</strong>: MarsReel Academy camps operate on a schedule that is communicated to participants and their guardians before the camp starts. Any changes to the schedule will be promptly communicated.<br />
                2. <strong>Supervision</strong>: Qualified staff members supervise all activities to ensure participant safety and program quality.<br />
                3. <strong>Equipment</strong>: Participants must bring their own equipment unless otherwise specified. MarsReel Academy is not responsible for lost or stolen items.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Safety and Liability
            </Typography>
            <Typography paragraph>
                1. <strong>Injury Waiver</strong>: Participants and their guardians must sign an injury waiver acknowledging the inherent risks associated with athletic activities. Mars Reel Media Corp does not assume liability for injuries sustained during camp activities.<br />
                2. <strong>Medical Emergencies</strong>: In the event of an injury, our staff will provide immediate assistance and, if necessary, contact emergency services. Parents or guardians will be notified as soon as possible.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Code of Conduct
            </Typography>
            <Typography paragraph>
                1. <strong>Behavior Expectations</strong>: All participants are expected to behave respectfully towards peers, staff, and property. Disruptive or harmful behavior will not be tolerated and may result in dismissal from the camp.<br />
                2. <strong>Disciplinary Actions</strong>: Failure to adhere to the code of conduct may result in verbal warnings, temporary suspension from activities, or expulsion from the camp without refund.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Privacy and Data Protection
            </Typography>
            <Typography paragraph>
                1. <strong>Data Collection</strong>: We collect personal information necessary for registration, emergency contact, and program operations. This data is protected and used in accordance with our privacy policy.<br />
                2. <strong>Confidentiality</strong>: Participant information is confidential and will not be shared with third parties without consent, except as required by law.
            </Typography>
            <Typography variant="h6" gutterBottom>
                Contact Information
            </Typography>
            <Typography paragraph>
                For any questions or concerns regarding our policies or camp operations, please contact us at support@marsreel.com. We are committed to providing a positive and enriching experience for all participants and their families.
            </Typography>
        </StyledContainer>
    );
};

export default CompanyPolicy;
