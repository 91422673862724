// UpdateContactInfo.js
import React, { useState } from 'react';
import { Box, Typography, TextField, Button, IconButton, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const UpdateContactInfo = ({
                               email = '',
                               setEmail,
                               phone = '',
                               setPhone,
                               street = '',
                               setStreet,
                               city = '',
                               setCity,
                               state = '',
                               setState,
                               zipCode = '',
                               setZipCode,
                               country = '',
                               setCountry,
                           }) => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">Update Contact Information</Typography>
                <IconButton onClick={handleToggle}>
                    {open ? <ExpandLess style={{ color: '#fff' }} /> : <ExpandMore style={{ color: '#fff' }} />}
                </IconButton>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    InputLabelProps={{
                        style: { color: 'rgba(255, 255, 255, 0.6)' },
                    }}
                    InputProps={{
                        style: { color: '#fff' },
                    }}
                />
                <TextField
                    label="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    InputLabelProps={{
                        style: { color: 'rgba(255, 255, 255, 0.6)' },
                    }}
                    InputProps={{
                        style: { color: '#fff' },
                    }}
                />
                <TextField
                    label="Street"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    InputLabelProps={{
                        style: { color: 'rgba(255, 255, 255, 0.6)' },
                    }}
                    InputProps={{
                        style: { color: '#fff' },
                    }}
                />
                <TextField
                    label="City"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    InputLabelProps={{
                        style: { color: 'rgba(255, 255, 255, 0.6)' },
                    }}
                    InputProps={{
                        style: { color: '#fff' },
                    }}
                />
                <TextField
                    label="State"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    InputLabelProps={{
                        style: { color: 'rgba(255, 255, 255, 0.6)' },
                    }}
                    InputProps={{
                        style: { color: '#fff' },
                    }}
                />
                <TextField
                    label="Zip Code"
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    InputLabelProps={{
                        style: { color: 'rgba(255, 255, 255, 0.6)' },
                    }}
                    InputProps={{
                        style: { color: '#fff' },
                    }}
                />
                <TextField
                    label="Country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    InputLabelProps={{
                        style: { color: 'rgba(255, 255, 255, 0.6)' },
                    }}
                    InputProps={{
                        style: { color: '#fff' },
                    }}
                />
                <Button variant="contained" onClick={handleToggle} style={{ marginTop: '0.5rem', backgroundColor: '#12c099' }}>
                    Save
                </Button>
            </Collapse>
        </Box>
    );
};

export default UpdateContactInfo;
