import React, { useState } from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase'; // Update the path

// Use environment variable for API base URL with a fallback
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.academy.marsreel.com';
console.log('API_BASE_URL:', API_BASE_URL); // Debugging line to check the value

const Root = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#141414',
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    zIndex: 2,
    marginTop: '4rem',
});

const FormContainer = styled(Container)({
    backgroundColor: 'rgba(51, 51, 51, 0.8)',
    padding: '1rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    marginTop: '1rem',
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    marginTop: '0.5rem',
});

const StyledTextField = styled(TextField)({
    marginBottom: '0.5rem',
});

const AdminAddLocation = () => {
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [country, setCountry] = useState('');
    const [message, setMessage] = useState('');
    const [authCredentials, setAuthCredentials] = useState({
        email: '',
        password: ''
    });
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, authCredentials.email, authCredentials.password);
            setIsAuthenticated(true);
        } catch (error) {
            setMessage('Authentication failed');
            console.error('Error logging in:', error);
        }
    };

    const handleSubmit = async () => {
        try {
            const fullAddress = `${city}, ${state}, ${zipCode}, ${country}`;
            console.log('Submitting address:', fullAddress);

            const response = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
                params: {
                    address: fullAddress,
                    key: 'AIzaSyB6p0ytdfkpa0JOTb0XOgon5xoYEFPWz1c'
                }
            });

            console.log('Geocoding API response:', response.data);

            if (response.data.status === 'OK') {
                const addressComponents = response.data.results[0].address_components;
                let formattedCity = '';
                let formattedState = '';
                let formattedZipCode = '';
                let formattedCountry = '';

                addressComponents.forEach(component => {
                    const types = component.types;
                    console.log('Component types:', types);
                    if (types.includes('locality')) {
                        formattedCity = component.long_name;
                    }
                    if (types.includes('administrative_area_level_1')) {
                        formattedState = component.short_name;
                    }
                    if (types.includes('postal_code')) {
                        formattedZipCode = component.long_name;
                    }
                    if (types.includes('country')) {
                        formattedCountry = component.long_name;
                    }
                });

                const locationData = {
                    city: formattedCity || city,
                    state: formattedState || state,
                    zipCode: formattedZipCode || zipCode,
                    country: formattedCountry || country
                };

                console.log('Formatted location data:', locationData);

                const saveResponse = await axios.post(`${API_BASE_URL}/api/location/add-location`, locationData);

                console.log('Save response:', saveResponse.data);

                if (saveResponse.data.success) {
                    setMessage('Location added successfully!');
                } else {
                    setMessage('Failed to add location.');
                }
            } else {
                console.log('Geocoding API response status:', response.data.status);
                setMessage('Invalid address.');
            }
        } catch (error) {
            setMessage('An error occurred.');
            console.error('Error adding location:', error);
        }
    };

    if (!isAuthenticated) {
        return (
            <Root>
                <StyledContainer maxWidth="sm">
                    <Typography variant="h4" gutterBottom>
                        Admin Login
                    </Typography>
                    <FormContainer>
                        <StyledTextField
                            label="Email"
                            value={authCredentials.email}
                            onChange={(e) => setAuthCredentials({ ...authCredentials, email: e.target.value })}
                            fullWidth
                            margin="dense"
                            variant="filled"
                            InputLabelProps={{
                                style: { color: '#fff' },
                            }}
                            InputProps={{
                                style: { color: '#fff' },
                            }}
                        />
                        <StyledTextField
                            label="Password"
                            type="password"
                            value={authCredentials.password}
                            onChange={(e) => setAuthCredentials({ ...authCredentials, password: e.target.value })}
                            fullWidth
                            margin="dense"
                            variant="filled"
                            InputLabelProps={{
                                style: { color: '#fff' },
                            }}
                            InputProps={{
                                style: { color: '#fff' },
                            }}
                        />
                        <ActionButton fullWidth variant="contained" onClick={handleLogin}>
                            Login
                        </ActionButton>
                    </FormContainer>
                    {message && (
                        <Typography variant="body1" style={{ marginTop: '1rem', color: '#12c099' }}>
                            {message}
                        </Typography>
                    )}
                </StyledContainer>
            </Root>
        );
    }

    return (
        <Root>
            <StyledContainer maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    Add New Location
                </Typography>
                <FormContainer>
                    <StyledTextField
                        label="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="Zip Code"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <StyledTextField
                        label="Country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <ActionButton fullWidth variant="contained" onClick={handleSubmit}>
                        Add Location
                    </ActionButton>
                </FormContainer>
                {message && (
                    <Typography variant="body1" style={{ marginTop: '1rem', color: '#12c099' }}>
                        {message}
                    </Typography>
                )}
            </StyledContainer>
        </Root>
    );
};

export default AdminAddLocation;
