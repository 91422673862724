import React from 'react';
import { Container, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    paddingTop: '6rem', // Adjust this value to ensure content is not under the navigation bar
    paddingBottom: '2rem',
    minHeight: '100vh',
});

const Section = styled('div')({
    marginBottom: '1.5rem',
});

const Privacy = () => {
    return (
        <StyledContainer maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Privacy Policy
            </Typography>
            <Section>
                <Typography variant="h6" gutterBottom>
                    Section 1: Information We Collect
                </Typography>
                <Typography variant="body1">
                    Mars Reel Media Corp collects various types of information in connection with the services we provide, including personal data such as your name, email address, phone number, and payment information.
                </Typography>
            </Section>
            <Section>
                <Typography variant="h6" gutterBottom>
                    Section 2: How We Use Your Information
                </Typography>
                <Typography variant="body1">
                    We use the information we collect to provide, maintain, and improve our services, including to process transactions, communicate with you, and to deliver targeted advertisements and services.
                </Typography>
            </Section>
            <Section>
                <Typography variant="h6" gutterBottom>
                    Section 3: Sharing Your Information
                </Typography>
                <Typography variant="body1">
                    We may share your information with third parties for purposes such as processing payments, providing customer support, and for marketing and advertising purposes. We ensure these third parties comply with strict data protection regulations.
                </Typography>
            </Section>
            <Section>
                <Typography variant="h6" gutterBottom>
                    Section 4: Data Security
                </Typography>
                <Typography variant="body1">
                    We implement various security measures to protect your personal data from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.
                </Typography>
            </Section>
            <Section>
                <Typography variant="h6" gutterBottom>
                    Section 5: Your Data Protection Rights
                </Typography>
                <Typography variant="body1">
                    You have the right to request access to, correction of, or deletion of your personal data. You can also object to the processing of your personal data in certain circumstances. To exercise these rights, please contact us at privacy@marsreel.com.
                </Typography>
            </Section>
            {/* Add more sections as needed */}
        </StyledContainer>
    );
};

export default Privacy;
