import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, AppBar, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from './firebase'; // Update the path

const Root = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledAppBar = styled(AppBar)({
    backgroundColor: 'rgba(20, 20, 20, 0.8)',
    borderBottom: '1px solid #e6e6e6',
    zIndex: 10,
    position: 'fixed',
    top: 0,
    width: '100%',
});

const Logo = styled('img')({
    height: 'auto',
    width: '20%',
    maxWidth: '100px',
    minWidth: '50px',
});

const VideoBackground = styled('video')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: -1,
    '@media (max-width: 600px)': {
        display: 'none',
    },
});

const Overlay = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Brighter tint
    zIndex: 1,
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    zIndex: 2,
    marginTop: '4rem', // Adjusted for fixed header
});

const FormContainer = styled(Container)({
    backgroundColor: 'rgba(51, 51, 51, 0.8)',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    marginTop: '2rem',
    zIndex: 2,
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099', // Updated green color
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85', // Darker green on hover
    },
    marginTop: '1rem',
});

const ForgotPassword = () => {
    const [email, setEmail] = useState('');

    const handleResetPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, email);
            console.log('Reset password email sent to:', email);
        } catch (error) {
            console.error('Error sending reset password email:', error);
        }
    };

    useEffect(() => {
        const video = document.getElementById('background-video');
        if (video) {
            video.play().catch((error) => {
                console.error('Error attempting to play video:', error);
            });
        }
    }, []);

    return (
        <Root>
            <VideoBackground id="background-video" autoPlay loop muted playsInline>
                <source src="https://www-media.marsreel.com/wp-content/uploads/2019/03/19235924/MARS-REEL_SPLASH_V04C.mp4" type="video/mp4" />
            </VideoBackground>
            <Overlay />

            <StyledAppBar position="fixed">
                <Toolbar>
                    <Logo src="https://marsreel.com/wp-content/themes/marsreel/images/shared/marsreel-logo.png" alt="MarsReel" />
                </Toolbar>
            </StyledAppBar>

            <StyledContainer maxWidth="sm">
                <Typography variant="h4" gutterBottom>
                    Forgot Password
                </Typography>
                <FormContainer>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="dense"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: '#fff' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <ActionButton fullWidth variant="contained" onClick={handleResetPassword}>
                        Reset Password
                    </ActionButton>
                </FormContainer>
            </StyledContainer>
        </Root>
    );
};

export default ForgotPassword;
