import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography, TextField } from '@mui/material';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { styled } from '@mui/system';

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    marginTop: '0.5rem',
});

// Define styles for CardElement to match TextField
const cardElementOptions = {
    style: {
        base: {
            fontSize: '16px', // Match the font size of TextField
            color: '#fff',
            '::placeholder': {
                color: '#aab7c4',
            },
            padding: '12px', // Adjust padding to match TextField height
        },
        invalid: {
            color: '#fa755a',
        },
    },
};

const PaymentForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = location.state || { user: null }; // Getting user details from the location state

    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements || !user) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        setLoading(true);

        try {
            // Step 1: Create Stripe customer
            const stripeCustomerResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/stripe/create-customer`, {
                email: user.email,
                name: `${firstName} ${lastName}`,
            });

            const { id: stripeCustomerId } = stripeCustomerResponse.data;

            // Step 2: Update MongoDB with Stripe customer ID
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/update-stripe-customer-id`, {
                firebaseUid: user.firebaseUid,
                stripeCustomerId,
            });

            // Step 3: Create payment method and attach it to the customer
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: `${firstName} ${lastName}`,
                    email: user.email,
                },
            });

            if (error) {
                setError(error.message);
                setLoading(false);
            } else {
                // Step 4: Attach payment method to Stripe customer
                const attachResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/stripe/attach-payment-method`, {
                    customerId: stripeCustomerId,
                    paymentMethodId: paymentMethod.id,
                });

                if (attachResponse.data.success) {
                    // Step 5: Subscribe the user to the Memberful plan
                    const subscriptionSlug = '32595-premium-monthly';
                    const subscribeResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/memberful/update-card-and-subscribe`, {
                        email: user.email,
                        cardToken: paymentMethod.id,
                        subscriptionSlug,
                        firebaseUid: user.firebaseUid,
                    });

                    if (subscribeResponse.data.success) {
                        navigate('/start-training');
                    } else {
                        setError('Failed to subscribe to Memberful plan');
                        setLoading(false);
                    }
                } else {
                    setError('Failed to attach payment method');
                    setLoading(false);
                }
            }
        } catch (error) {
            setError('Failed to create Stripe customer or setup payment');
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                label="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
                margin="normal"
                variant="filled"
                InputLabelProps={{
                    style: { color: '#fff' },
                }}
                InputProps={{
                    style: { color: '#fff' },
                }}
            />
            <TextField
                label="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                fullWidth
                margin="normal"
                variant="filled"
                InputLabelProps={{
                    style: { color: '#fff' },
                }}
                InputProps={{
                    style: { color: '#fff' },
                }}
            />
            <div style={{ margin: '16px 0' }}> {/* Add margin to separate from text fields */}
                <CardElement options={cardElementOptions} />
            </div>
            <ActionButton type="submit" disabled={!stripe || loading}>
                {loading ? 'Processing...' : 'Next'}
            </ActionButton>
            {error && <Typography variant="body2" color="error">{error}</Typography>}
        </form>
    );
};

export default PaymentForm;
