import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
    paddingTop: '6rem', // Adjust this value to ensure content is not under the navigation bar
    paddingBottom: '2rem',
    minHeight: '100vh',
    backgroundColor: '#ffffff', // White background
    color: '#000000', // Black text color
});

const StyledAccordion = styled(Accordion)({
    marginBottom: '1rem',
    width: '100%',
    backgroundColor: '#f1f1f1', // Light grey background for accordion
    color: '#000000', // Black text color
    border: '1px solid #ddd', // Subtle border
    boxShadow: 'none', // Remove box-shadow
    '&:before': {
        display: 'none', // Remove default expansion indicator
    }
});

const StyledAccordionSummary = styled(AccordionSummary)({
    backgroundColor: '#f1f1f1', // Light grey background for summary
    '& .MuiTypography-root': {
        fontWeight: 'bold', // Bold text for questions
    },
    '& .MuiAccordionSummary-expandIcon': {
        color: '#000000', // Black expand icon
    }
});

const StyledAccordionDetails = styled(AccordionDetails)({
    backgroundColor: '#ffffff', // White background for details
});

const StyledTable = styled(Table)({
    marginTop: '1rem',
    '& th': {
        fontWeight: 'bold',
    }
});

const FAQ = () => {
    const today = new Date().toLocaleDateString();

    return (
        <StyledContainer maxWidth="lg">
            <Typography variant="h4" gutterBottom>
                Frequently Asked Questions
            </Typography>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">What is Mars Reel Academy?</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Typography>
                        Mars Reel Academy provides various types of unique experiences and activities that offer our athlete community ample opportunities for enrichment and skill development. This includes sports training clinics, scrimmages, fireside chats, and virtual events. Currently, we offer basketball classes, with plans to introduce soccer, football, lacrosse, track and field, tennis, and martial arts in the near future.
                    </Typography>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">How do I sign up?</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Typography>
                        You can sign up by clicking on the "Create Account" button on the home page and filling out the required information. Choose a plan that fits your needs and complete the registration process.
                    </Typography>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Pay As You Go Explained</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Typography>
                        The Pay As You Go plan allows you to purchase sessions individually as needed. This flexible plan ensures that you only pay for the sessions you attend, making it a cost-effective option for those who may not train regularly.
                    </Typography>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Recurring Plan Explained</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Typography>
                        Our Recurring Plan is a subscription service priced at $250 per month. Subscribers receive 8 credits to their wallet every month, which can be used to book sessions. As a commitment to our monthly subscribers, these credits are provided at a 20% discount per credit compared to individual session purchases. Users who consume their 8 credits may purchase additional credits through their dashboard to join more sessions, but these will be at the regular price of $40 per credit.
                    </Typography>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">What are credits?</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Typography>
                        Credits are required in the Mars Reel Academy app to access various types of unique experiences and activities. This includes training clinics, scrimmages, fireside chats, and virtual events. Credits cost $40 for one credit.
                    </Typography>
                    <Typography variant="body1" style={{ marginTop: '1rem' }}>
                        Credit pricing schedule as of {today}
                    </Typography>
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <TableCell>Event Type</TableCell>
                                <TableCell>Credits</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Training Session</TableCell>
                                <TableCell>2 credits</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Exclusive In-Person Fireside Chat</TableCell>
                                <TableCell>3 credits</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Virtual Fireside Chat</TableCell>
                                <TableCell>1 credit</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Virtual Mental Training Classes</TableCell>
                                <TableCell>1 credit</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Scrimmage with Referee</TableCell>
                                <TableCell>4 credits</TableCell>
                            </TableRow>
                        </TableBody>
                    </StyledTable>
                    <Typography variant="body2" style={{ marginTop: '1rem' }}>
                        These are illustrative examples of how credits can be used on the platform.
                    </Typography>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">How can I purchase additional credits?</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Typography>
                        Credits can be purchased with or without a subscription plan at <a href="https://academy.marsreel.com/dashboard">academy.marsreel.com/dashboard</a>. If you have a recurring subscription, your credit balance will reload every month with your subscription. If you'd like to access more activities, you can purchase additional credits directly through the dashboard or by opting into our text-to-purchase service.
                    </Typography>
                </StyledAccordionDetails>
            </StyledAccordion>
            <StyledAccordion>
                <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">What is the refund policy?</Typography>
                </StyledAccordionSummary>
                <StyledAccordionDetails>
                    <Typography>
                        While we typically issue credits to your account, we do offer cash refunds in certain circumstances, such as accidental charges. Each request is reviewed individually, considering the specific details and circumstances to ensure fairness and alignment with our company policies.

                        Credits or refunds are provided at our discretion and do not imply any admission of liability. You’ll usually be informed about any credits or refunds through your account dashboard, though we may also contact you directly. If you don’t hear from us, it means we’re unable to provide a refund or credit for your request.

                        Please note that processing a refund or credit typically takes up to 7 days. We strive to issue refunds to the original payment method, but if that’s not possible, we’ll find an alternative solution. Thank you for your understanding and cooperation.
                    </Typography>
                </StyledAccordionDetails>
            </StyledAccordion>
        </StyledContainer>
    );
};

export default FAQ;
