// src/components/PrivateRoute.js
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

const PrivateRoute = ({ component: Component, affiliateOnly = false, ...rest }) => {
    const { currentUser, loading } = useAuth();
    const [isAffiliate, setIsAffiliate] = useState(false);
    const [checkingAffiliate, setCheckingAffiliate] = useState(true);

    useEffect(() => {
        const checkAffiliateStatus = async () => {
            if (currentUser) {
                try {
                    const response = await axios.get(`https://api.academy.marsreel.com/api/affiliates/${currentUser.uid}`);
                    setIsAffiliate(response.data.isAffiliate);
                } catch (error) {
                    console.error('Error checking affiliate status:', error);
                }
            }
            setCheckingAffiliate(false);
        };

        if (affiliateOnly) {
            checkAffiliateStatus();
        } else {
            setCheckingAffiliate(false);
        }
    }, [currentUser, affiliateOnly]);

    if (loading || checkingAffiliate) {
        return <div>Loading...</div>; // Show a loading indicator while checking auth and affiliate status
    }

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    if (affiliateOnly && !isAffiliate) {
        return <Navigate to="/unauthorized" />;
    }

    return <Component {...rest} />;
};

export default PrivateRoute;
