import React, { useState } from 'react';
import { Box, Typography, List, ListItem, ListItemText, Collapse, Pagination, IconButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const MyReferrals = () => {
    const [expanded, setExpanded] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const referralsPerPage = 5;

    const referrals = [
        { name: 'John Doe', stages: [true, false, false, false, false] },
        { name: 'Jane Smith', stages: [true, true, true, true, true] },
        // Add more dummy referrals as needed
    ];

    const handleExpandClick = (index) => {
        const newExpanded = [...expanded];
        newExpanded[index] = !newExpanded[index];
        setExpanded(newExpanded);
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const indexOfLastReferral = currentPage * referralsPerPage;
    const indexOfFirstReferral = indexOfLastReferral - referralsPerPage;
    const currentReferrals = referrals.slice(indexOfFirstReferral, indexOfLastReferral);

    const stagesList = [
        'Invitation Sent on June 15, 2024',
        'Registration Complete on June 20, 2024',
        'Attended 1st Session on July 10, 2024',
        'Credit Reward Pending',
        '2 Credits Issued on August 1, 2024'
    ];

    const getLatestStage = (stages) => {
        const latestStageIndex = stages.lastIndexOf(true);
        return stagesList[latestStageIndex];
    };

    const renderReferralStages = (stages) => (
        <Box display="flex" flexDirection="column" alignItems="flex-start" marginTop="1rem">
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start" width="100%">
                {stagesList.map((stage, index) => (
                    <Box key={index} display="flex" alignItems="center" margin="0.5rem">
                        {stages[index] ? (
                            <CheckCircleIcon size={20} style={{ color: '#12c099', marginRight: '0.5rem' }} />
                        ) : (
                            <CheckCircleOutlineIcon size={20} style={{ color: '#12c099', marginRight: '0.5rem' }} />
                        )}
                        <Typography variant="body2" style={{ color: '#12c099', fontSize: '0.8rem' }}>{stage}</Typography>
                        {index < 4 && <Box flex={1} height="2px" backgroundColor="#12c099" />}
                    </Box>
                ))}
            </Box>
        </Box>
    );

    return (
        <Box
            bgcolor="#202020"
            color="#fff"
            p={3}
            borderRadius={2}
            boxShadow={3}
            marginBottom="1rem"
        >
            <Typography variant="h6" align="left">My Referrals ({referrals.length})</Typography>
            <List>
                {currentReferrals.map((referral, index) => (
                    <React.Fragment key={index}>
                        <ListItem button onClick={() => handleExpandClick(index)}>
                            <ListItemText
                                primary={referral.name}
                                secondary={<span style={{ color: '#12c099' }}>Status: {getLatestStage(referral.stages)}</span>}
                                style={{ textAlign: 'left' }}
                            />
                            {expanded[index] ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                            <Box ml={3}>
                                {renderReferralStages(referral.stages)}
                            </Box>
                        </Collapse>
                    </React.Fragment>
                ))}
            </List>
            <Box display="flex" justifyContent="center" mt={2}>
                <Pagination
                    count={Math.ceil(referrals.length / referralsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                />
            </Box>
        </Box>
    );
};

export default MyReferrals;
