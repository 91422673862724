import React, { useState, useEffect } from 'react';
import { Typography, Button, Box, Checkbox, Tooltip, Paper, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';

const InfoBox = styled(Paper)({
    backgroundColor: '#202020',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'left',
    color: '#fff',
    marginBottom: '1rem',
    width: '100%',
});

const BillingManagement = ({
                               user,
                               setUser,
                               subscriptionStarted,
                               setSubscriptionStarted,
                               subscriptionPaused,
                               setSubscriptionPaused,
                               marsClubChecked,
                               setMarsClubChecked,
                               autoRebill,
                               setAutoRebill,
                           }) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [creditCardOpen, setCreditCardOpen] = useState(false);
    const [pauseOpen, setPauseOpen] = useState(false);
    const [hasBillingMethod, setHasBillingMethod] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSubscriptionStatus = async () => {
            if (user && user.stripeCustomerId) {
                try {
                    const response = await axios.get(`https://api.academy.marsreel.com/api/stripe/subscription-status`, {
                        params: { customerId: user.stripeCustomerId },
                    });
                    const { isActive, isPaused } = response.data;
                    setSubscriptionStarted(isActive);
                    setSubscriptionPaused(isPaused);
                } catch (error) {
                    console.error('Error fetching subscription status:', error);
                    setSubscriptionStarted(false);
                    setSubscriptionPaused(false);
                }
            }
        };

        const fetchBillingMethod = async () => {
            if (user && user.stripeCustomerId) {
                try {
                    const response = await axios.get(`https://api.academy.marsreel.com/api/stripe/default-payment-method`, {
                        params: { customerId: user.stripeCustomerId },
                    });
                    setHasBillingMethod(!!response.data.paymentMethodId);
                } catch (error) {
                    console.error('Failed to fetch default payment method:', error);
                    setHasBillingMethod(false);
                }
            } else {
                setHasBillingMethod(false);
            }
        };

        if (user) {
            fetchSubscriptionStatus();
            fetchBillingMethod();
        }
    }, [user, setSubscriptionStarted, setSubscriptionPaused]);

    const handleStartSubscription = async () => {
        if (!hasBillingMethod) {
            setError('Please add a billing method first.');
            return;
        }
        setLoading(true);
        try {
            if (!user.stripeCustomerId) {
                const stripeCustomerResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/stripe/create-customer`, {
                    email: user.email,
                    name: `${user.firstName} ${user.lastName}`,
                });

                const { id: stripeCustomerId } = stripeCustomerResponse.data;

                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/update-stripe-customer-id`, {
                    firebaseUid: user.firebaseUid,
                    stripeCustomerId,
                });

                setUser(prevUser => ({ ...prevUser, stripeCustomerId }));
            }

            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/stripe/create-subscription`, {
                customerId: user.stripeCustomerId,
                priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
            });

            setSubscriptionStarted(true);
            setSubscriptionPaused(false);
            setConfirmOpen(false);
        } catch (error) {
            console.error('Failed to start subscription:', error);
            setError('Failed to start subscription. Please try again later.');
            setConfirmOpen(false);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenCreditCard = () => setCreditCardOpen(true);

    const handleCloseCreditCard = () => setCreditCardOpen(false);

    const handleResumeSubscription = async () => {
        try {
            await axios.post(`https://api.academy.marsreel.com/api/stripe/resume-subscription`, {
                customerId: user.stripeCustomerId,
            });
            setSubscriptionPaused(false);
            setSubscriptionStarted(true);
        } catch (error) {
            console.error('Failed to resume subscription:', error);
        }
    };

    const handlePauseSubscription = async () => {
        try {
            await axios.post(`https://api.academy.marsreel.com/api/stripe/pause-subscription`, {
                customerId: user.stripeCustomerId,
            });
            setSubscriptionPaused(true);
        } catch (error) {
            console.error('Failed to pause subscription:', error);
        }
    };

    const handleCloseConfirm = () => setConfirmOpen(false);

    const handleConfirmPause = () => {
        setSubscriptionPaused(true);
        setPauseOpen(false);
    };

    const handleClosePause = () => setPauseOpen(false);

    const handleMarsClubCheckbox = () => setMarsClubChecked(!marsClubChecked);

    return (
        <InfoBox>
            <Typography variant="h6">Manage Billing</Typography>
            <Button
                variant="contained"
                fullWidth
                onClick={handleOpenCreditCard}
                style={{ marginTop: '0.5rem', backgroundColor: '#12c099' }}
            >
                {hasBillingMethod ? 'Update Credit Card' : 'Add Credit Card'}
            </Button>
            {hasBillingMethod ? (
                subscriptionStarted ? (
                    subscriptionPaused ? (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleResumeSubscription}
                            style={{ marginTop: '0.5rem', backgroundColor: '#12c099' }}
                        >
                            Resume Subscription
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handlePauseSubscription}
                            style={{ marginTop: '0.5rem', backgroundColor: '#12c099' }}
                        >
                            Pause Subscription
                        </Button>
                    )
                ) : (
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleStartSubscription}
                        style={{ marginTop: '0.5rem', backgroundColor: '#12c099' }}
                        disabled={loading}
                    >
                        {loading ? 'Processing...' : 'Start Subscription'}
                    </Button>
                )
            ) : (
                <Typography variant="body2" style={{ marginTop: '1rem', color: '#fff' }}>
                    Please add a billing method to get started.
                </Typography>
            )}
            {hasBillingMethod && (
                <>
                    <Box display="flex" alignItems="center" marginTop="1rem">
                        <Checkbox
                            checked={marsClubChecked}
                            onChange={handleMarsClubCheckbox}
                            style={{ color: 'green' }}
                        />
                        <Typography variant="body2">
                            Subscribe me to Mars Club for a 30-day free trial of exclusive interviews, articles, and video content on marsreel.com
                        </Typography>
                        <Tooltip title="Mars Club is our members-only area that houses ultra-premium HD content, such as behind-the-scenes action, health and fitness content to learn how your favorite athletes train, and access exclusive shows that live only on Mars Club.">
                            <InfoIcon fontSize="small" style={{ marginLeft: '0.5rem', color: '#12c099' }} />
                        </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center" marginTop="1rem">
                        <Checkbox
                            checked={autoRebill}
                            onChange={() => setAutoRebill(!autoRebill)}
                            style={{ color: 'green' }}
                        />
                        <Typography variant="body2">
                            Automatically charge my card when my credit balance reaches less than two credits or zero.
                        </Typography>
                    </Box>
                </>
            )}

            {/* Dialogs for various actions */}
            <Dialog open={confirmOpen} onClose={handleCloseConfirm} PaperProps={{ style: { backgroundColor: '#1c1c1c' } }}>
                <DialogTitle style={{ color: '#fff' }}>Confirm Subscription</DialogTitle>
                <DialogContent>
                    <Typography style={{ color: '#fff' }}>
                        Are you sure you want to start your subscription?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} style={{ color: '#12c099' }}>
                        No
                    </Button>
                    <Button onClick={handleStartSubscription} style={{ color: '#12c099' }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={creditCardOpen} onClose={handleCloseCreditCard} PaperProps={{ style: { backgroundColor: '#1c1c1c' } }}>
                <DialogTitle style={{ color: '#fff' }}>Update Credit Card</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Credit Card Number"
                        fullWidth
                        margin="normal"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: 'rgba(255, 255, 255, 0.6)' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label="Expiration Date"
                        fullWidth
                        margin="normal"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: 'rgba(255, 255, 255, 0.6)' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                    <TextField
                        label="CVV"
                        fullWidth
                        margin="normal"
                        variant="filled"
                        InputLabelProps={{
                            style: { color: 'rgba(255, 255, 255, 0.6)' },
                        }}
                        InputProps={{
                            style: { color: '#fff' },
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCreditCard} style={{ color: '#12c099' }}>
                        Cancel
                    </Button>
                    <Button onClick={handleCloseCreditCard} style={{ color: '#12c099' }}>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={pauseOpen} onClose={handleClosePause} PaperProps={{ style: { backgroundColor: '#1c1c1c' } }}>
                <DialogTitle style={{ color: '#fff' }}>Pause Subscription</DialogTitle>
                <DialogContent>
                    <Typography style={{ color: '#fff' }}>Are you sure you want to pause your subscription?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePause} style={{ color: '#12c099' }}>
                        No
                    </Button>
                    <Button onClick={handleConfirmPause} style={{ color: '#12c099' }}>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </InfoBox>
    );
};

export default BillingManagement;
