import React, { useState, useEffect } from 'react';
import { Button, Typography, Container, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import axios from 'axios';
import Bowser from 'bowser';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    backgroundColor: '#141414',
    color: '#fff',
});

const StyledContainer = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#282828',
    padding: '1rem',
    borderRadius: '12px',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.3)',
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    marginTop: '1rem',
    textTransform: 'none',
    fontSize: '1rem',
    padding: '0.75rem 2rem',
});

const CodeContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0.5rem',
});

const CodeInput = styled('input')({
    width: '2rem',
    height: '2rem',
    fontSize: '1rem',
    textAlign: 'center',
    margin: '0 0.25rem',
    backgroundColor: '#282828',
    color: '#fff',
    border: '1px solid #fff',
    borderRadius: '8px',
    outline: 'none',
    transition: 'border-color 0.3s',
    '&:focus': {
        borderColor: '#12c099',
    },
});

const SmallText = styled(Typography)({
    fontSize: '0.8rem',
});

const PhoneVerification = ({ handleLogin }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedPlan, userData } = location.state || { selectedPlan: 'No plan selected', userData: {} };
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [isLoading, setIsLoading] = useState(false);
    const [verificationCode, setVerificationCode] = useState(null);
    const [deviceId, setDeviceId] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        const deviceInfo = browser.getResult();
        setDeviceId(JSON.stringify(deviceInfo));
    }, []);

    const handleChange = (index, value) => {
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value && index < 5) {
                document.getElementById(`code-${index + 1}`).focus();
            }
        }
    };

    const handleSendCode = async () => {
        try {
            const response = await axios.post('https://api.academy.marsreel.com/api/users/send-code', { phone: userData.phone });
            setVerificationCode(response.data.verificationCode); // Save code for demo purposes
        } catch (error) {
            console.error('Sending code failed:', error);
        }
    };

    const handleVerify = async () => {
        setIsLoading(true);
        setError('');
        try {
            const response = await axios.post('https://api.academy.marsreel.com/api/users/verify-code', {
                phone: userData.phone,
                code: code.join(''),
                verificationCode, // Include code for demo purposes
                deviceId, // Include device ID
            });

            if (response.data.verified) {
                // Check if device ID already exists in the user collection
                const deviceCheckResponse = await axios.post('https://api.academy.marsreel.com/api/users/check-device-id', { deviceId });
                if (deviceCheckResponse.data.exists) {
                    setError('This device is already registered.');
                    setIsLoading(false);
                    return;
                }

                // Create user in MongoDB
                const createUserResponse = await axios.post('https://api.academy.marsreel.com/api/users/create-account', { ...userData, deviceId });
                console.log('User created:', createUserResponse.data.user);

                if (createUserResponse.data.waitlist) {
                    navigate('/sorry', { state: { cityName: userData.city } });
                } else {
                    navigate('/setup-payment', { state: { selectedPlan } });
                }
            } else {
                setError('Verification failed. Please check the code and try again.');
            }
        } catch (error) {
            setError('Verification failed. Please try again.');
            console.error('Verification failed:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Root>
            <NavigationBar isLoggedIn={false} handleLogout={() => {}} />
            <StyledContainer maxWidth="xs">
                <Typography variant="h5" gutterBottom>
                    Verify Your Phone Number
                </Typography>
                <SmallText variant="body1" gutterBottom>
                    Please enter the security code sent to your phone number.
                </SmallText>
                <CodeContainer>
                    {code.map((digit, index) => (
                        <CodeInput
                            key={index}
                            id={`code-${index}`}
                            type="text"
                            maxLength="1"
                            value={digit}
                            onChange={(e) => handleChange(index, e.target.value)}
                            aria-label={`Digit ${index + 1}`}
                        />
                    ))}
                </CodeContainer>
                <ActionButton
                    fullWidth
                    variant="contained"
                    onClick={handleSendCode}
                    disabled={isLoading}
                    aria-label="Send Code"
                >
                    Send Code
                </ActionButton>
                <ActionButton
                    fullWidth
                    variant="contained"
                    onClick={handleVerify}
                    disabled={isLoading}
                    aria-label="Verify Code"
                >
                    {isLoading ? <CircularProgress size={24} /> : 'Verify'}
                </ActionButton>
                {error && <Typography variant="body2" color="error">{error}</Typography>}
            </StyledContainer>
        </Root>
    );
};

export default PhoneVerification;
