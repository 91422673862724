// components/APIDocumentation.js
import React, { useState } from 'react';
import { Container, Paper, Typography, Box, IconButton, Collapse, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CopyIcon from '@mui/icons-material/CopyAll';

const Root = styled('div')({
    position: 'relative',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#1c1c1c',
    padding: '2rem',
});

const InfoBox = styled(Paper)({
    backgroundColor: '#202020',
    padding: '2rem',
    borderRadius: '12px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.7)',
    textAlign: 'left',
    color: '#fff',
    marginBottom: '2rem',
    width: '100%',
    maxWidth: '100%',
});

const Section = styled(Box)({
    marginBottom: '2rem',
});

const CodeBox = styled('pre')({
    backgroundColor: '#1c1c1c',
    color: '#fff',
    padding: '1.5rem',
    borderRadius: '8px',
    overflowX: 'auto',
    position: 'relative',
    fontSize: '1rem',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
});

const CopyButton = styled(IconButton)({
    position: 'absolute',
    top: '0.5rem',
    right: '0.5rem',
    color: '#12c099',
    '&:hover': {
        color: '#0f9f85',
    },
});

const ExpandButton = styled(IconButton)({
    color: '#12c099',
    '&:hover': {
        color: '#0f9f85',
    },
});

const Header = styled(Typography)({
    marginTop: '2rem',
    marginBottom: '1rem',
    fontSize: '2.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
    '@media (max-width: 600px)': {
        fontSize: '2rem',
    },
});

const SubHeader = styled(Typography)({
    marginBottom: '1.5rem',
    fontSize: '1.25rem',
    textAlign: 'center',
    '@media (max-width: 600px)': {
        fontSize: '1rem',
    },
});

const SummaryParagraph = styled(Typography)({
    marginBottom: '2rem',
    fontSize: '1rem',
    textAlign: 'left',
    width: '100%',
    '@media (max-width: 600px)': {
        fontSize: '0.9rem',
    },
});

const APIDocumentation = () => {
    const [expandedSections, setExpandedSections] = useState({});

    const toggleSection = (section) => {
        setExpandedSections((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
    };

    const renderSection = (title, routes, baseURL, description) => (
        <InfoBox>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" gutterBottom>
                    {title}
                </Typography>
                <ExpandButton onClick={() => toggleSection(title)}>
                    {expandedSections[title] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ExpandButton>
            </Box>
            <Typography variant="body1" gutterBottom>
                <strong>API URL:</strong> {baseURL}
            </Typography>
            <Collapse in={expandedSections[title]}>
                <Typography variant="body2" paragraph>
                    {description}
                </Typography>
                {routes.map((route) => (
                    <Box key={route.endpoint} mb={2}>
                        <Typography variant="body2">
                            <strong>{route.method} {route.endpoint}</strong> - {route.description}
                        </Typography>
                        {route.payload && (
                            <>
                                <Typography variant="body2">
                                    <strong>Example Payload:</strong>
                                </Typography>
                                <CodeBox>
                                    <CopyButton onClick={() => handleCopy(JSON.stringify(route.payload, null, 2))}>
                                        <CopyIcon />
                                    </CopyButton>
                                    {JSON.stringify(route.payload, null, 2)}
                                </CodeBox>
                            </>
                        )}
                    </Box>
                ))}
            </Collapse>
        </InfoBox>
    );

    return (
        <Root>
            <Container maxWidth="lg">
                <Header>API Documentation</Header>
                <SubHeader>Explore our APIs and learn how to integrate with MarsReel Academy</SubHeader>
                <SummaryParagraph>
                    Welcome to the API documentation for the Mars Reel Academy group of APIs. The Mars Reel Academy platform is meticulously designed to prioritize flexibility and scalability. Leveraging a microservices architecture, our platform is composed of five distinct full-stack applications, all seamlessly integrated with a shared MongoDB Atlas cloud database. Our main web application, built with React.js, and four separate mobile applications, crafted using React Native, ensure a robust and dynamic user experience. Each application is deployed in isolated Docker containers on Heroku, facilitating easy scalability, maintenance, and uptime. This strategic isolation allows us to independently enhance features, fix bugs, and manage issues efficiently. Our architecture integrates a variety of services, including AWS for storage and distribution, Docker for containerization, Google Maps for location services, Stripe for payment processing, and Firebase for authentication and real-time database needs. This comprehensive and thoughtfully constructed system ensures a seamless, reliable, and scalable experience for our community.
                </SummaryParagraph>
                {renderSection(
                    'Web App (Registration App)',
                    [
                        { method: 'POST', endpoint: '/create-account', description: 'Create a new user account.', payload: { firstName: 'John', lastName: 'Doe', email: 'john.doe@example.com', phone: '1234567890', password: 'password123', birthday: '2000-01-01', gender: 'male', city: 'New York', state: 'NY', zipCode: '10001', firebaseUid: 'firebase_uid' } },
                        { method: 'POST', endpoint: '/send-code', description: 'Send a verification code to the user\'s phone.', payload: { phone: '1234567890' } },
                        { method: 'POST', endpoint: '/verify-code', description: 'Verify the phone number with the sent code.', payload: { phone: '1234567890', code: '123456' } },
                        { method: 'POST', endpoint: '/manage-mars-club', description: 'Manage Mars Club subscription for the user.', payload: { userId: 'user123', subscribe: true } },
                        { method: 'GET', endpoint: '/credits', description: 'Get user credits.' },
                        { method: 'POST', endpoint: '/update-credits', description: 'Update user credits.', payload: { userId: 'user123', credits: 100 } },
                        { method: 'POST', endpoint: '/update-password', description: 'Update user password.', payload: { email: 'john.doe@example.com', newPassword: 'newpassword123' } },
                    ],
                    'https://api.academy.marsreel.com',
                    'The Signup App handles user registration, verification, and subscription management for MarsReel Academy. The `firebaseUid` is a crucial field fetched from our MongoDB database, which is used to pull fields from documents to complete most backend functions.'
                )}
                {renderSection(
                    'Athlete App',
                    [
                        { method: 'GET', endpoint: '/', description: 'Get all users.' },
                        { method: 'GET', endpoint: '/parent/:parentUserId', description: 'Get parent user by ID.' },
                        { method: 'GET', endpoint: '/13+athlete/:thirteenPlusAthleteUserId', description: 'Get 13+ athlete by ID.' },
                        { method: 'GET', endpoint: '/u13athlete/:u13AthleteUserId', description: 'Get U13 athlete by ID.' },
                        { method: 'GET', endpoint: '/coach/:coachUserId', description: 'Get coach by ID.' },
                        { method: 'GET', endpoint: '/hostingOrganization/:hostingOrganizationUserId', description: 'Get hosting organization by ID.' },
                        { method: 'GET', endpoint: '/siteManager/:siteManagerUserId', description: 'Get site manager by ID.' },
                        { method: 'GET', endpoint: '/admin/:adminUserId', description: 'Get admin by ID.' },
                        { method: 'GET', endpoint: '/advertiser/:advertiserUserId', description: 'Get advertiser by ID.' },
                        { method: 'GET', endpoint: '/:firebaseUid/credits', description: 'Fetch credits for a user.', payload: { firebaseUid: 'user123' } },
                        { method: 'POST', endpoint: '/:userId/wallet/top-up', description: 'Top up wallet for a user.', payload: { userId: 'user123', amount: 50 } },
                        { method: 'GET', endpoint: '/:userId/wallet/transactions', description: 'List wallet transactions for a user.' },
                        { method: 'POST', endpoint: '/:userId/wallet', description: 'Create a wallet for a user.', payload: { userId: 'user123', initialBalance: 100 } },
                        { method: 'GET', endpoint: '/:userId/wallet', description: 'Get a wallet for a user.' },
                        { method: 'PUT', endpoint: '/:userId/wallet', description: 'Update a wallet for a user.', payload: { userId: 'user123', newBalance: 150 } },
                        { method: 'DELETE', endpoint: '/:userId/wallet', description: 'Delete a wallet for a user.' },
                        { method: 'GET', endpoint: '/:userId/wallets', description: 'List all wallets for a user.' },
                        { method: 'GET', endpoint: '/sessions', description: 'Get a list of all sessions.' },
                        { method: 'POST', endpoint: '/sessions', description: 'Create a new session.', payload: { name: 'Session Name', description: 'Session Description', duration: 60, creditsRequired: 2, capacity: 20, schedule: { recurring: true, flexSchedule: [{ date: '2024-06-01', startTime: '10:00', endTime: '11:00' }] }, organization: [{ id: 'orgId', name: 'Org Name' }], skills: [{ id: 'skillId', name: 'Skill Name' }], venue: { id: 'venueId', name: 'Venue Name', location: { type: 'Point', coordinates: [-74.006, 40.7128], address: '123 Main Street' } }, coaches: ['coachId'], sponsorName: 'Sponsor Name', sponsorLogoUrl: 'https://example.com/logo.png', remainingSlots: 10 } },
                        { method: 'PUT', endpoint: '/sessions/:id', description: 'Update a session.', payload: { name: 'Updated Session Name', description: 'Updated Session Description', duration: 60, creditsRequired: 2, capacity: 20, schedule: { recurring: true, flexSchedule: [{ date: '2024-06-01', startTime: '10:00', endTime: '11:00' }] }, organization: [{ id: 'orgId', name: 'Org Name' }], skills: [{ id: 'skillId', name: 'Skill Name' }], venue: { id: 'venueId', name: 'Venue Name', location: { type: 'Point', coordinates: [-74.006, 40.7128], address: '123 Main Street' } }, coaches: ['coachId'], sponsorName: 'Sponsor Name', sponsorLogoUrl: 'https://example.com/logo.png', remainingSlots: 10 } },
                        { method: 'DELETE', endpoint: '/sessions/:id', description: 'Delete a session.' },
                        { method: 'GET', endpoint: '/sessions/search', description: 'Search for sessions.', payload: { query: 'Search Term', lat: 40.7128, lon: -74.006, maxDistance: 10 } },
                    ],
                    'https://api.athlete.academy.marsreel.com',
                    'The Athlete App is designed for athletes to manage their profiles, track their credits, and interact with their wallets and other users. The search functionality allows users to find sessions based on various criteria such as name, description, skills, and location.'
                )}
                {renderSection(
                    'Checkin App',
                    [
                        { method: 'POST', endpoint: '/attendee/check-in', description: 'Check-in an attendee.', payload: { attendeeId: 'attendee123', eventId: 'event123' } },
                        { method: 'POST', endpoint: '/attendee/qr-check-in', description: 'Check-in an attendee using QR code.', payload: { qrCode: 'abc123' } },
                        { method: 'PUT', endpoint: '/site-manager/update-profile', description: 'Update profile information for a site manager.', payload: { siteManagerId: 'manager123', name: 'Jane Smith', phone: '9876543210' } },
                        { method: 'GET', endpoint: '/jobs/available', description: 'Get a list of available jobs for site managers.' },
                        { method: 'GET', endpoint: '/jobs/upcoming', description: 'Get a list of upcoming jobs for site managers.' },
                        { method: 'POST', endpoint: '/jobs/:jobId/accept', description: 'Accept a job.', payload: { jobId: 'job123' } },
                        { method: 'POST', endpoint: '/jobs/:jobId/cancel', description: 'Cancel a job.', payload: { jobId: 'job123' } },
                        { method: 'GET', endpoint: '/jobs/past', description: 'Get a list of past jobs for site managers.' },
                    ],
                    'https://api.checkin.academy.marsreel.com',
                    'The Checkin App facilitates event check-ins for attendees and provides site managers with job management features.'
                )}
                {renderSection(
                    'Admin App',
                    [
                        { method: 'GET', endpoint: '/events/upcoming', description: 'Get a list of upcoming events.' },
                        { method: 'GET', endpoint: '/events/past', description: 'Get a list of past events.' },
                        { method: 'POST', endpoint: '/events', description: 'Create a new event.', payload: { name: 'Event Name', date: '2024-07-21', time: '10:00', location: 'Location Name' } },
                        { method: 'PUT', endpoint: '/events/:eventId', description: 'Update an event.', payload: { name: 'Updated Event Name', date: '2024-07-22', time: '11:00', location: 'Updated Location Name' } },
                        { method: 'DELETE', endpoint: '/events/:eventId', description: 'Delete an event.' },
                        { method: 'GET', endpoint: '/sessions', description: 'Get a list of all sessions.' },
                        { method: 'POST', endpoint: '/sessions', description: 'Create a new session.', payload: { name: 'Session Name', date: '2024-07-21', time: '10:00', coachId: 'coach123', creditsRequired: 5, capacity: 20 } },
                        { method: 'PUT', endpoint: '/sessions/:sessionId', description: 'Update a session.', payload: { name: 'Updated Session Name', date: '2024-07-22', time: '11:00', coachId: 'coach123', creditsRequired: 5, capacity: 20 } },
                        { method: 'DELETE', endpoint: '/sessions/:sessionId', description: 'Delete a session.' },
                        { method: 'POST', endpoint: '/sessions/:sessionId/duplicate', description: 'Duplicate a session.' },
                        { method: 'GET', endpoint: '/coaches', description: 'Get a list of coaches.' },
                        { method: 'POST', endpoint: '/coaches', description: 'Add a new coach.', payload: { name: 'Coach Name', sport: 'Sport Name', city: 'City Name', state: 'State Name', zipCode: '12345' } },
                        { method: 'PUT', endpoint: '/coaches/:coachId', description: 'Update information about a coach.', payload: { name: 'Updated Coach Name', sport: 'Updated Sport Name', city: 'Updated City Name', state: 'Updated State Name', zipCode: '12345' } },
                        { method: 'DELETE', endpoint: '/coaches/:coachId', description: 'Delete a coach.' },
                        { method: 'GET', endpoint: '/site-managers', description: 'Get a list of site managers.' },
                        { method: 'POST', endpoint: '/site-managers', description: 'Create a new site manager.', payload: { name: 'Site Manager Name', city: 'City Name', state: 'State Name', zipCode: '12345' } },
                        { method: 'PUT', endpoint: '/site-managers/:siteManagerId', description: 'Update information about a site manager.', payload: { name: 'Updated Site Manager Name', city: 'Updated City Name', state: 'Updated State Name', zipCode: '12345' } },
                        { method: 'DELETE', endpoint: '/site-managers/:siteManagerId', description: 'Delete a site manager.' },
                        { method: 'PUT', endpoint: '/profile', description: 'Update admin profile information.', payload: { name: 'Admin Name', email: 'admin@example.com', phone: '1234567890' } },
                        { method: 'PUT', endpoint: '/notifications', description: 'Update admin notifications settings.', payload: { emailNotifications: true, smsNotifications: true } },
                        { method: 'PUT', endpoint: '/change-password', description: 'Change admin password.', payload: { oldPassword: 'oldpassword123', newPassword: 'newpassword123' } },
                    ],
                    'https://api.admin.academy.marsreel.com',
                    'The Admin App is designed for managing events, sessions, coaches, and site managers within the MarsReel Academy ecosystem.'
                )}
                {renderSection(
                    'Coaches App',
                    [
                        { method: 'PUT', endpoint: '/update-info', description: 'Update personal information, bank information, and schedule for a coach.', payload: { name: 'Coach Name', address: '123 Street, City, State, ZIP', bankInfo: 'Bank Info', schedule: 'Schedule Info' } },
                    ],
                    'https://api.coaches.academy.marsreel.com',
                    'The Coaches App allows coaches to update their personal information, manage their schedules, and access job-related functionalities.'
                )}
            </Container>
        </Root>
    );
};

export default APIDocumentation;
