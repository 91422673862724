import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import NavigationBar from './NavigationBar';
import ChoosePlan from './ChoosePlan';
import CreateAccount from './CreateAccount';
import SetupPayment from './SetupPayment';
import StartTraining from './StartTraining';
import PhoneVerification from './PhoneVerification';
import Dashboard from './Dashboard';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import Waitlist from './Waitlist';
import AdminAddLocation from './components/AdminAddLocation';
import PrivateRoute from './components/PrivateRoute';
import FAQ from './FAQ';
import HelpCenter from './HelpCenter';
import TermsOfUse from './TermsOfUse';
import Privacy from './Privacy';
import CompanyPolicy from './CompanyPolicy';
import APIDocumentation from './components/APIDocumentation';
import EngineeringAndDataScience from './components/EngineeringAndDataScience'; // New import
import { useAuth } from './context/AuthContext';

function App() {
    const { currentUser, logout } = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(!!currentUser);
    const [showDashboardLink, setShowDashboardLink] = useState(!!currentUser);
    const [cityName, setCityName] = useState('');
    const [locationNotSupported, setLocationNotSupported] = useState(false); // Track unsupported location
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = async () => {
        try {
            await logout();
            setIsLoggedIn(false);
            setShowDashboardLink(false);
            navigate('/login');
        } catch (error) {
            console.error('Failed to log out', error);
        }
    };

    const handleLogin = (city) => {
        setIsLoggedIn(true);
        setShowDashboardLink(true);
        setCityName(city);
        setLocationNotSupported(false); // Reset the unsupported location state on login
        navigate('/dashboard');
    };

    useEffect(() => {
        // If the user was redirected to the waitlist due to location not being supported,
        // we need to disable the logged-in state across all pages.
        if (location.pathname === '/waitlist') {
            setIsLoggedIn(false);
            setShowDashboardLink(false);
            setLocationNotSupported(true);
        }
    }, [location]);

    // Determine if the NavigationBar should display the Login button only
    const shouldShowLoginOnly = locationNotSupported;

    return (
        <div style={{ height: '100%' }}>
            {/* Conditionally render the NavigationBar based on the current state */}
            <NavigationBar
                isLoggedIn={shouldShowLoginOnly ? false : isLoggedIn}
                showDashboardLink={shouldShowLoginOnly ? false : showDashboardLink}
                handleLogout={handleLogout}
            />
            <Routes>
                <Route path="/choose-plan" element={<ChoosePlan />} />
                <Route path="/create-account" element={<CreateAccount handleLogin={handleLogin} />} />
                <Route path="/setup-payment" element={<SetupPayment setShowDashboardLink={setShowDashboardLink} />} />
                <Route path="/phone-verification" element={<PhoneVerification handleLogin={handleLogin} />} />
                <Route path="/start-training" element={<StartTraining />} />
                <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
                <Route path="/login" element={<Login handleLogin={handleLogin} />} />
                <Route path="/forgot-password" element={<ForgotPassword handleLogin={handleLogin} />} />
                <Route path="/waitlist" element={<Waitlist cityName={cityName} />} />
                <Route path="/admin/add-location" element={<PrivateRoute component={AdminAddLocation} />} />
                <Route path="/api-documentation" element={<PrivateRoute component={APIDocumentation} />} />
                <Route path="/engineering" element={<EngineeringAndDataScience />} /> {/* New route */}
                <Route path="/faq" element={<FAQ />} />
                <Route path="/help-center" element={<HelpCenter />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/company-policy" element={<CompanyPolicy />} />
                <Route path="/" element={<ChoosePlan />} />
            </Routes>
        </div>
    );
}

export default App;
