import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import NavigationBar from './NavigationBar'; // Make sure to import NavigationBar

const Root = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#141414', // Netflix black background
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '2rem',
    paddingBottom: '2rem',
    zIndex: 2,
    marginTop: '4rem', // Adjusted for fixed header
});

const FreeSessionText = styled(Typography)({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#12c099',
    marginBottom: '1rem',
    zIndex: 2,
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    margin: '0.5rem',
    padding: '1rem 2rem',
    display: 'flex',
    alignItems: 'center',
});

const IconBox = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
});

const StartTraining = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsAuthenticated(!!user);
        });

        // Cleanup the subscription on component unmount
        return () => unsubscribe();
    }, []);

    const handleDashboard = () => {
        if (isAuthenticated) {
            navigate('/dashboard');
        } else {
            navigate('/login');
        }
    };

    return (
        <Root>
            <NavigationBar isLoggedIn={true} handleLogout={() => {}} /> {/* Add NavigationBar with isLoggedIn set to true */}
            <StyledContainer maxWidth="md">
                <Typography variant="h4" gutterBottom>
                    Welcome to Mars Reel Academy!
                </Typography>
                <FreeSessionText>Your account has been set up successfully. You can now start training!</FreeSessionText>
                <IconBox>
                    <ActionButton
                        href="https://apps.apple.com/us/app/mars-reel-academy/id6680150371"
                        target="_blank"
                        startIcon={<AppleIcon />}
                    >
                        Download for iOS
                    </ActionButton>
                    <ActionButton
                        href="https://play.google.com"
                        target="_blank"
                        startIcon={<AndroidIcon />}
                    >
                        Download for Android
                    </ActionButton>
                </IconBox>
                <IconBox>
                    <ActionButton onClick={handleDashboard}>
                        Go to Dashboard
                    </ActionButton>
                </IconBox>
            </StyledContainer>
        </Root>
    );
};

export default StartTraining;
