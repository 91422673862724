import React, { useEffect, useState } from 'react';
import { Typography, Paper } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

// Styled InfoBox similar to the original styling in your code
const InfoBox = styled(Paper)({
    backgroundColor: '#202020',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'left',
    color: '#fff',
    marginBottom: '1rem',
    width: '100%',
});

const NextBillingDate = ({ user }) => {
    const [nextBillingText, setNextBillingText] = useState('Your default plan is pay as you go until you start your subscription');

    useEffect(() => {
        const fetchUserBillingDetails = async () => {
            if (user && user.stripeCustomerId) {
                try {
                    // Fetch billing details including next billing date from the new subscription status endpoint
                    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/stripe/subscription-status`, {
                        params: { customerId: user.stripeCustomerId },
                    });
                    const { isActive, isPaused, nextBillingDate } = response.data;

                    if (isActive) {
                        if (isPaused) {
                            setNextBillingText('Your recurring plan is paused. Your default plan is pay as you go until you resume your subscription.');
                        } else if (nextBillingDate) {
                            setNextBillingText(`Your next billing date is ${new Date(nextBillingDate).toLocaleDateString()}`);
                        } else {
                            setNextBillingText('Your active subscription does not have a next billing date available.');
                        }
                    } else {
                        // No active subscription found; default to pay-as-you-go message
                        setNextBillingText('Your default plan is pay as you go until you start your subscription');
                    }
                } catch (error) {
                    console.error('Failed to fetch billing details:', error);
                    // Default to pay-as-you-go message instead of showing an error
                    setNextBillingText('Your default plan is pay as you go until you start your subscription');
                }
            }
        };

        fetchUserBillingDetails();
    }, [user]);

    return (
        <InfoBox>
            <Typography variant="h6">Next Billing Date</Typography>
            <Typography variant="body2" style={{ marginTop: '0.5rem' }}>
                {nextBillingText}
            </Typography>
        </InfoBox>
    );
};

export default NextBillingDate;
