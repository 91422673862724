import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const CustomTShirtSelection = () => {
    const [tShirtSelections, setTShirtSelections] = useState([]);
    const [tShirtSizes, setTShirtSizes] = useState({});
    const [tShirtModalOpen, setTShirtModalOpen] = useState(false);
    const [tShirtStatus, setTShirtStatus] = useState('');
    const [trackingUrl, setTrackingUrl] = useState('');

    const handleSelectSize = (shirtName, size) => {
        setTShirtSizes({ ...tShirtSizes, [shirtName]: size });
    };

    const handleSelectShirt = (shirtName) => {
        let updatedSelections;
        if (tShirtSelections.includes(shirtName)) {
            updatedSelections = tShirtSelections.filter(name => name !== shirtName);
            const { [shirtName]: removed, ...remainingSizes } = tShirtSizes;
            setTShirtSizes(remainingSizes);
        } else {
            if (tShirtSelections.length < 2) {
                updatedSelections = [...tShirtSelections, shirtName];
            } else {
                alert('You can only select up to 2 shirts.');
                return;
            }
        }
        setTShirtSelections(updatedSelections);
    };

    const handleSubmitShirts = () => {
        if (tShirtSelections.length !== 2) {
            alert('Please select exactly 2 shirts.');
            return;
        }
        // Handle the shirt selection submission logic here
        setTShirtStatus('processing');
        setTrackingUrl('https://www.ups.com/track?loc=en_US&requester=ST&tracknum=YOUR_TRACKING_NUMBER'); // Placeholder URL with UPS tracking
        setTShirtModalOpen(false);
    };

    const renderTrackingUI = () => (
        <Box display="flex" flexDirection="column" alignItems="center" marginTop="1rem">
            <Box display="flex" flexWrap="wrap" justifyContent="center" width="100%">
                {[
                    'Processing', 'Preparing for Shipment', 'In Transit', 'Delivered'
                ].map((stage, index) => (
                    <Box key={index} display="flex" alignItems="center" margin="0.5rem">
                        <CheckCircleIcon size={20} style={{ color: '#12c099', marginRight: '0.5rem' }} />
                        <Typography variant="body2" style={{ color: '#12c099', fontSize: '0.8rem' }}>{stage}</Typography>
                        {index < 3 && <Box flex={1} height="2px" backgroundColor="#12c099" />}
                    </Box>
                ))}
            </Box>
            <Button href={trackingUrl} target="_blank" style={{ color: '#12c099', marginTop: '0.5rem' }}>
                Track your order
            </Button>
        </Box>
    );

    return (
        <Box
            sx={{
                backgroundColor: '#202020',
                padding: '1.5rem',
                borderRadius: '8px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
                textAlign: 'left',
                color: '#fff',
                marginBottom: '1rem'
            }}
        >
            {tShirtStatus && (
                <Box display="flex" justifyContent="center" alignItems="center" width="100%" padding="1rem 0" bgcolor="#202020" marginBottom="2rem">
                    {renderTrackingUI()}
                </Box>
            )}
            {!tShirtStatus && (
                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h6">Select Your T-Shirts</Typography>
                    <Button variant="contained" onClick={() => setTShirtModalOpen(true)} style={{ marginTop: '0.5rem', backgroundColor: '#12c099' }}>
                        Select T-Shirts
                    </Button>
                </Box>
            )}

            <Dialog open={tShirtModalOpen} onClose={() => setTShirtModalOpen(false)} PaperProps={{ style: { backgroundColor: '#1c1c1c', maxWidth: '700px', width: '100%' } }}>
                <DialogTitle style={{ color: '#fff' }}>Select Your T-Shirts</DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        {[
                            {
                                name: "Women's Long Sleeve",
                                img: 'https://mms-images.out.customink.com/mms/images/catalog/80ff19f647e77cf9fc0f5121a4e38174/styles/1527800/supporting/1.jpg?ixq=60&ixw=450',
                                sizes: ['YXS', 'YS', 'YM', 'YL', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL']
                            },
                            {
                                name: "Women's Short Sleeve",
                                img: 'https://mms-images.out.customink.com/mms/images/catalog/93ef97a98fdbe59fec407d5976c347d2/styles/1019000/supporting/1.jpg?ixq=60&ixw=450',
                                sizes: ['YXS', 'YS', 'YM', 'YL', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL']
                            },
                            {
                                name: "Men's Long Sleeve",
                                img: 'https://mms-images.out.customink.com/mms/images/catalog/cc9ad555405acee88727d35d0b2c64dc/styles/213700/supporting/1.jpg?ixq=60&ixw=450',
                                sizes: ['YXS', 'YS', 'YM', 'YL', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL']
                            },
                            {
                                name: "Men's Short Sleeve",
                                img: 'https://mms-images.out.customink.com/mms/images/catalog/9268c3c7abe4d04bd5d733f0b3b4a731/styles/1018800/supporting/1.jpg?ixq=60&ixw=450',
                                sizes: ['YXS', 'YS', 'YM', 'YL', 'XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL']
                            }
                        ].map(shirt => (
                            <Box key={shirt.name} marginBottom="1rem" position="relative">
                                <Box
                                    component="img"
                                    src={shirt.img}
                                    alt={shirt.name}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        border: tShirtSelections.includes(shirt.name) ? '2px solid #12c099' : '2px solid transparent',
                                        cursor: tShirtSelections.includes(shirt.name) || tShirtSelections.length < 2 ? 'pointer' : 'not-allowed'
                                    }}
                                    onClick={() => (tShirtSelections.includes(shirt.name) || tShirtSelections.length < 2) && handleSelectShirt(shirt.name)}
                                />
                                {tShirtSelections.includes(shirt.name) && (
                                    <CheckCircleIcon style={{
                                        position: 'absolute',
                                        top: '10px',
                                        right: '10px',
                                        color: '#12c099',
                                        fontSize: '2rem'
                                    }} />
                                )}
                                <Typography variant="body2" style={{ marginTop: '0.5rem', color: '#fff' }}>
                                    Sizes Available in:
                                </Typography>
                                <Box display="flex" flexWrap="wrap">
                                    {shirt.sizes.map(size => (
                                        <Button
                                            key={size}
                                            variant="outlined"
                                            style={{
                                                color: tShirtSizes[shirt.name] === size ? '#fff' : '#12c099',
                                                borderColor: '#12c099',
                                                backgroundColor: tShirtSizes[shirt.name] === size ? '#12c099' : 'transparent',
                                                margin: '0.2rem',
                                                flex: '1 0 21%'
                                            }}
                                            onClick={() => tShirtSelections.includes(shirt.name) && handleSelectSize(shirt.name, size)}
                                            disabled={!tShirtSelections.includes(shirt.name)}
                                        >
                                            {size}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTShirtModalOpen(false)} style={{ color: '#12c099' }}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmitShirts} style={{ color: '#12c099' }}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CustomTShirtSelection;
