import React, { useState } from 'react';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, IconButton, TextField } from '@mui/material';
import { styled } from '@mui/system';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import axios from 'axios';

const CREDIT_PRICE_CENTS = parseInt(process.env.REACT_APP_CREDIT_PRICE_CENTS, 10);

const WalletBox = styled(Box)({
    backgroundColor: '#12c099',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
    color: '#fff',
    marginBottom: '1rem',
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    marginTop: '1rem',
    textTransform: 'none',
    fontSize: '1rem',
    width: '100%',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
});

const CreditDial = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '2rem 0',
});

const CreditButton = styled(IconButton)({
    color: '#12c099',
    '&:hover': {
        color: '#0f9f85',
    },
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    margin: '0 0.5rem',
});

const CreditInput = styled(TextField)({
    '& .MuiInputBase-input': {
        textAlign: 'center',
        fontSize: '2rem',
        padding: '0.5rem',
        color: '#000',
    },
    '& .MuiInputBase-root': {
        backgroundColor: '#fff',
        borderRadius: '4px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    },
    '& .MuiFilledInput-underline:before': {
        borderBottomColor: 'transparent',
    },
    '& .MuiFilledInput-underline:after': {
        borderBottomColor: 'transparent',
    },
});

const CreditBalance = ({ credits, setCredits, user }) => {
    const [open, setOpen] = useState(false);
    const [confirmPurchaseOpen, setConfirmPurchaseOpen] = useState(false);
    const [creditAmount, setCreditAmount] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [hasBillingMethod, setHasBillingMethod] = useState(false);

    const handleOpen = async () => {
        // Check for billing method before opening the purchase modal
        if (user && user.stripeCustomerId) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/stripe/default-payment-method`, {
                    params: { customerId: user.stripeCustomerId },
                });
                setHasBillingMethod(!!response.data.paymentMethodId);
                setOpen(true);
            } catch (error) {
                console.error('Failed to fetch default payment method:', error);
                setHasBillingMethod(false);
                alert('Please add a billing method to purchase credits.');
            }
        } else {
            setHasBillingMethod(false);
            alert('Please add a billing method to purchase credits.');
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const increaseCredits = () => {
        setCreditAmount(creditAmount + 1);
    };

    const decreaseCredits = () => {
        if (creditAmount > 1) {
            setCreditAmount(creditAmount - 1);
        }
    };

    const handlePurchase = () => {
        setConfirmPurchaseOpen(true);
    };

    const handleConfirmPurchase = async () => {
        setIsLoading(true);
        console.log('Initiating purchase process:', { creditAmount, creditPriceCents: CREDIT_PRICE_CENTS });

        try {
            console.log('User data:', user);

            if (!user || !user.stripeCustomerId) {
                console.error('User or Stripe customer ID is missing:', { user });
                throw new Error("User or Stripe customer ID not found");
            }

            console.log('Creating payment intent:', {
                customerId: user.stripeCustomerId,
                amount: creditAmount * CREDIT_PRICE_CENTS,
            });

            const paymentIntentResponse = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/stripe/create-payment-intent`, {
                customerId: user.stripeCustomerId,
                amount: creditAmount * CREDIT_PRICE_CENTS,
            });

            console.log('Payment Intent response:', paymentIntentResponse.data);

            const { id: transactionId, status, charges } = paymentIntentResponse.data;

            if (status !== 'succeeded') {
                console.error('Payment not confirmed. Status:', status);
                throw new Error('Payment could not be confirmed. Please check your payment method.');
            }

            const paymentUrl = charges?.data?.[0]?.receipt_url || 'URL not available';
            console.log('Payment confirmed. Updating user credits:', { transactionId, paymentUrl });

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/update-credits`, {
                firebaseUid: user.firebaseUid,
                credits: creditAmount,
                amount: creditAmount * CREDIT_PRICE_CENTS,
                transactionId,
                status,
                url: paymentUrl,
            });

            console.log('User credits updated successfully:', response.data);
            setCredits(response.data.newBalance);
            setConfirmPurchaseOpen(false);
            handleClose();
        } catch (error) {
            console.error('Failed to complete purchase:', error.message, error.response?.data);
            alert(`An error occurred while processing your purchase: ${error.message}`);
        } finally {
            setIsLoading(false);
            console.log('Purchase process completed.');
        }
    };

    const handleClosePurchaseConfirm = () => {
        setConfirmPurchaseOpen(false);
    };

    return (
        <>
            <WalletBox>
                <Typography variant="h5" component="div">Credit Balance</Typography>
                <Typography variant="h4" style={{ marginTop: '0.5rem' }}>{credits}</Typography>
                <ActionButton variant="contained" onClick={handleOpen}>Buy Credits</ActionButton>
            </WalletBox>

            <Dialog open={open} onClose={handleClose} PaperProps={{ style: { backgroundColor: '#1c1c1c' } }}>
                <DialogTitle style={{ color: '#fff' }}>Purchase Credits</DialogTitle>
                <DialogContent>
                    {hasBillingMethod ? (
                        <>
                            <CreditDial>
                                <CreditButton onClick={decreaseCredits}>
                                    <RemoveCircleOutlineIcon />
                                </CreditButton>
                                <CreditInput variant="filled" value={creditAmount} />
                                <CreditButton onClick={increaseCredits}>
                                    <AddCircleOutlineIcon />
                                </CreditButton>
                            </CreditDial>
                            <Typography variant="h6" align="center" style={{ color: '#fff' }}>
                                Cost: ${(creditAmount * CREDIT_PRICE_CENTS / 100).toFixed(2)}
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="body2" style={{ color: '#fff', textAlign: 'center' }}>
                            Please add a billing method to purchase credits.
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    {hasBillingMethod ? (
                        <>
                            <Button onClick={handleClose} style={{ color: '#12c099' }}>
                                Cancel
                            </Button>
                            <Button onClick={handlePurchase} style={{ color: '#12c099' }} disabled={isLoading}>
                                {isLoading ? 'Processing...' : 'Purchase'}
                            </Button>
                        </>
                    ) : (
                        <Button onClick={handleOpen} style={{ color: '#12c099' }}>
                            Add Credit Card
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            <Dialog open={confirmPurchaseOpen} onClose={handleClosePurchaseConfirm} PaperProps={{ style: { backgroundColor: '#1c1c1c' } }}>
                <DialogTitle style={{ color: '#fff' }}>Confirm Purchase</DialogTitle>
                <DialogContent>
                    <Typography style={{ color: '#fff' }}>Confirm ${(creditAmount * CREDIT_PRICE_CENTS / 100).toFixed(2)} purchase for {creditAmount} credits?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePurchaseConfirm} style={{ color: '#12c099' }}>
                        No
                    </Button>
                    <Button onClick={handleConfirmPurchase} style={{ color: '#12c099' }} disabled={isLoading}>
                        {isLoading ? 'Processing...' : 'Yes'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CreditBalance;
