import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Link, Paper } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';

const TransactionHistoryBox = styled(Paper)(({ theme }) => ({
    backgroundColor: '#202020',
    padding: '1.5rem',
    borderRadius: '8px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
    textAlign: 'left',
    color: '#fff',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
        padding: '1rem', // Adjust padding for smaller screens
    },
}));

const TransactionGrid = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr 1fr 1fr',
    gap: '0.5rem',
    alignItems: 'center',
    padding: '0.5rem 0',
    borderBottom: '1px solid #444',
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '0.8fr 2.2fr 0.8fr 0.8fr',
        fontSize: '0.75rem',
        gap: '0.3rem',
    },
}));

const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const Transactions = ({ firebaseUid }) => {
    const [transactions, setTransactions] = useState([]);
    const [showAllTransactions, setShowAllTransactions] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await axios.get(`https://api.academy.marsreel.com/api/users/${firebaseUid}/transactions`);

                if (response.data.transactions && response.data.transactions.length > 0) {
                    // Sort transactions by date in descending order
                    const sortedTransactions = response.data.transactions.sort((a, b) => {
                        return new Date(b.date).getTime() - new Date(a.date).getTime();
                    });
                    setTransactions(sortedTransactions);
                } else {
                    setError('You do not have any transactions.');
                }
            } catch (error) {
                setError('You do not have any transactions.');
                console.error('Failed to fetch transactions:', error);
            }
        };

        if (firebaseUid) {
            fetchTransactions();
        }
    }, [firebaseUid]);

    const toggleTransactionHistory = () => {
        setShowAllTransactions(!showAllTransactions);
    };

    return (
        <TransactionHistoryBox>
            <Typography variant="h6" style={{ marginBottom: '1rem' }}>Transaction History</Typography>
            {error && (
                <Typography variant="body2" style={{ color: 'red', marginTop: '1rem' }}>
                    {error}
                </Typography>
            )}
            {!error && (
                <TransactionGrid>
                    <Typography variant="body2">Date</Typography>
                    <Typography variant="body2">Transaction / Invoice Number</Typography>
                    <Typography variant="body2">Total</Typography>
                    <Typography variant="body2">Status</Typography>
                </TransactionGrid>
            )}
            {transactions.length > 0 && (
                transactions.slice(0, showAllTransactions ? transactions.length : 3).map((item, index) => (
                    <TransactionGrid key={index}>
                        <Typography variant="body2">
                            {item.date ? new Date(item.date).toLocaleDateString() : 'Invalid Date'}
                        </Typography>
                        <Link href={item.url} target="_blank" variant="body2" style={{ color: '#12c099' }}>
                            {truncateText(item.transaction || item.invoice_number || 'ID not available', 15)}
                        </Link>
                        <Typography variant="body2">{item.amount || 'N/A'}</Typography>
                        <Typography variant="body2">{item.status || 'N/A'}</Typography>
                    </TransactionGrid>
                ))
            )}
            {transactions.length > 3 && (
                <Button onClick={toggleTransactionHistory} style={{ marginTop: '1rem', color: '#12c099' }}>
                    {showAllTransactions ? 'Show Less' : 'Show More'}
                </Button>
            )}
        </TransactionHistoryBox>
    );
};

export default Transactions;
