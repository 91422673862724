import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, TextField, Button, Grid, Box, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import NavigationBar from './NavigationBar';
import axios from 'axios';

const Root = styled('div')({
    position: 'relative',
    overflow: 'hidden',
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#141414',
});

const StyledContainer = styled(Container)({
    textAlign: 'center',
    paddingTop: '5rem', // Adjusted for fixed header
    paddingBottom: '2rem',
    zIndex: 2,
});

const Title = styled(Typography)({
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
    color: '#12c099',
});

const Subtitle = styled(Typography)({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#b3b3b3',
    marginBottom: '2rem',
});

const ContactFormWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
});

const ContactForm = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: '600px',
    zIndex: 2,
    backgroundColor: '#1c1c1c',
    padding: '2rem',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
});

const ActionButton = styled(Button)({
    backgroundColor: '#12c099',
    color: '#fff',
    '&:hover': {
        backgroundColor: '#0f9f85',
    },
    marginTop: '1rem',
    padding: '1rem 2rem',
});

const Footer = styled('footer')({
    marginTop: '2rem',
    color: '#b3b3b3',
    zIndex: 2,
    width: '100%',
    textAlign: 'center',
});

const FooterLink = styled(Typography)({
    color: '#12c099',
    textDecoration: 'none',
    '&:hover': {
        color: '#0f9f85',
    },
});

const Waitlist = () => {
    const location = useLocation();
    const { cityName, waitlistData } = location.state || {};
    const [message, setMessage] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const addToWaitlist = async () => {
            try {
                await axios.post('/api/waitlist/add-to-waitlist', waitlistData);
            } catch (error) {
                console.error('Error adding to waitlist:', error);
            }
        };

        if (waitlistData) {
            addToWaitlist();
        }
    }, [waitlistData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/email/contact', { name, email, message });
            setResponseMessage('Thank you for contacting us. We will get back to you soon.');
        } catch (error) {
            console.error('Error sending message:', error);
            setResponseMessage('Failed to send message. Please try again later.');
        }
    };

    return (
        <Root>
            <NavigationBar isLoggedIn={false} handleLogout={() => {}} />
            <StyledContainer>
                <Title variant="h4">Exciting News! We're launching soon in {cityName || 'your city'}!</Title>
                <Subtitle variant="h6">
                    You've successfully joined our waitlist. Be among the first to experience our launch in {cityName || 'your city'}!
                </Subtitle>
                <ContactFormWrapper>
                    <ContactForm onSubmit={handleSubmit}>
                        <Typography variant="h6" gutterBottom>
                            Contact Us
                        </Typography>
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                        <TextField
                            label="Email"
                            type="email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            label="Message"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                        <ActionButton type="submit">Submit</ActionButton>
                    </ContactForm>
                </ContactFormWrapper>
                {responseMessage && (
                    <Typography variant="body1" color="error" style={{ marginTop: '1rem' }}>
                        {responseMessage}
                    </Typography>
                )}
            </StyledContainer>
            <Footer>
                <Grid container spacing={2} justifyContent="center" alignItems="center" flexWrap="wrap">
                    <Grid item>
                        <FooterLink variant="body2" component="a" href="/faq">FAQ</FooterLink>
                    </Grid>
                    <Grid item>
                        <FooterLink variant="body2" component="a" href="/help-center">Help Center</FooterLink>
                    </Grid>
                    <Grid item>
                        <FooterLink variant="body2" component="a" href="/terms-of-use">Terms of Use</FooterLink>
                    </Grid>
                    <Grid item>
                        <FooterLink variant="body2" component="a" href="/privacy">Privacy</FooterLink>
                    </Grid>
                    <Grid item>
                        <FooterLink variant="body2" component="a" href="/company-policy">Company Policy</FooterLink>
                    </Grid>
                    <Grid item>
                        <FooterLink variant="body2" component="a" href="/api-documentation">API Documentation</FooterLink>
                    </Grid>
                    <Grid item>
                        <FooterLink variant="body2" component="a" href="https://affiliate.marsreel.com">Ambassador Program</FooterLink>
                    </Grid>
                </Grid>
                <Typography variant="body2" style={{ marginTop: '1rem' }}>
                    Questions? Email us: support@marsreel.com
                </Typography>
            </Footer>
        </Root>
    );
};

export default Waitlist;
